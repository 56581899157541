export const plansCourtText = {
  BASICO: "O plano Básico permite adicionar apenas uma quadra",
  AVANCADO: "O plano Avançado permite adicionar no máximo 3 quadras",
  PRO: "O plano Pro permite adicionar no máximo 8 quadras",
  "": "",
};

export const plansCourtQtt = {
  BASICO: 1,
  AVANCADO: 3,
  PRO: 8,
  "": 0,
};
